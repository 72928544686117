<template>
    <b-sidebar
        id="add-new-user-sidebar"
        :visible="isAddNewUserSidebarActive"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        right
        @hidden="on_hidden"
        @change="(val) =>{$emit('update:is-add-new-user-sidebar-active', val)}"
    >
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">
                    Formulario de usuarios
                </h5>

                <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="hide"
                />

            </div>

            <!-- BODY -->
            <validation-observer
                #default="{ handleSubmit, invalid }"
                ref="refFormObserver"
            >
                <!-- Form -->
                <b-form
                    class="p-2"
                    @submit.prevent="handleSubmit(onSubmit)"
                    @reset.prevent="resetForm"
                >
                    <input type="hidden" name="id" value="">

                    <!-- status -->
                    <validation-provider
                        #default="validationContext"
                        name="category"
                        rules="required"
                    >
                        <b-form-group
                            label="Categoría del producto"
                            label-for="category_select"
                        >
                            <v-select
                                v-model="categorySelected"
                                :options="categories"
                                label="name"
                                class="assignee-selector"
                                input-id="category_select"
                                value="name"
                                :reduce="(option)=>option.id"
                            >

                                <template #selected-option="{ name }">
                                    <span class="ml-50 d-inline-block align-middle"> {{ name }}</span>
                                </template>
                            </v-select>

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>


                    <!-- Username -->
                    <validation-provider
                        #default="validationContext"
                        name="Username"
                        rules="required"
                    >
                        <b-form-group
                            label="Nombre de producto"
                            label-for="username"
                        >
                            <b-form-input
                                id="username"
                                v-model="userData.name"
                                :state="getValidationState(validationContext)"
                                trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Username -->
                    <validation-provider
                        #default="validationContext"
                        name="description"
                        rules="required"
                    >
                        <b-form-group
                            label="Descripción"
                            label-for="description"
                        >
                            <b-form-input
                                id="description"
                                v-model="userData.description"
                                :state="getValidationState(validationContext)"
                                trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Username -->
                    <validation-provider
                        #default="validationContext"
                        name="Precio"
                        rules="required"
                    >
                        <b-form-group
                            label="Precio"
                            label-for="price"
                        >
                            <b-form-input
                                id="price"
                                type="number"
                                v-model="userData.price"
                                :state="getValidationState(validationContext)"
                                trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <div class="my-2">
                        <b-card-text class="mb-0">
                            Mostrar en iOS
                        </b-card-text>
                        <b-form-checkbox
                            v-model="userData.status"
                            class="custom-control-primary"
                            name="check-button"
                            switch
                        />
                    </div>

                    <validation-provider
                        #default="validationContext"
                        name="multimedia"
                        v-if="userData.images.length===0"
                        rules="required"

                    >
                        <b-form-group
                            label="Imagen"
                            label-for="multimedia"
                        >
                            <b-form-file
                                id="multimedia"
                                accept="image/*"
                                @change="onImageSelected"
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <div v-if="userData.images.length>0">
                        <b-avatar :src="userData.images[0]" size="10rem" class="mr-1"></b-avatar>
                        <b-button type="button" variant="danger" @click="onDeleteImage">
                            <feather-icon
                                icon="Trash2Icon"
                                size="16"
                                class="align-middle"
                            />
                        </b-button>
                    </div>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-2"
                            type="submit"
                            :disabled="invalid || loading"
                        >
                            Guardar
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="button"
                            variant="outline-secondary"
                            @click="hide"
                        >
                            Cancelar
                        </b-button>
                    </div>

                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>
import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BCardText, BFormCheckbox, BFormFile, BAvatar
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {ref} from '@vue/composition-api'
import {required, alphaNum, email} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import {useToast} from "vue-toastification/composition"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from "vue-select"

export default {
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        BCardText,
        BFormCheckbox,
        BFormFile,
        BAvatar,

        // Form Validation
        ValidationProvider,
        ValidationObserver,

        vSelect

    },
    directives: {
        Ripple,
    },
    model: {
        prop: 'isAddNewUserSidebarActive',
        event: 'update:is-add-new-user-sidebar-active',
    },
    props: {
        isAddNewUserSidebarActive: {
            type: Boolean,
            required: true,
        },
        userSelected: {
            type: Object,
            required: false
        },
        addNewUser: {
            type: Boolean,
            required: true
        }
    },
    watch: {
        addNewUser: function (newValue) {
            if (newValue) {
                this.userData = {
                    category: null,
                    description: '',
                    name: '',
                    price: 0,
                    status: true,
                    images: []
                }
            } else {
                this.categorySelected = this.userSelected.category.id
                this.userData = {
                    id: this.userSelected.id,
                    category: null,
                    description: this.userSelected.description,
                    name: this.userSelected.name,
                    price: this.userSelected.price,
                    status: this.userSelected.status,
                    images: this.userSelected.images,
                }
            }
        },
        userSelected: function (newValue, oldValue) {
            if (newValue.id && oldValue.id && newValue.id.toString() !== oldValue.id.toString()) {
                this.categorySelected = this.userSelected.category.id
                this.userData = {
                    id: this.userSelected.id,
                    category: null,
                    description: this.userSelected.description,
                    name: this.userSelected.name,
                    price: this.userSelected.price,
                    status: this.userSelected.status,
                    images: this.userSelected.images,
                }
            } else if (!newValue.id) {
                this.userData = {
                    category: null,
                    description: '',
                    name: '',
                    price: 0,
                    status: true,
                    images: []
                }
            }
        }
    },
    methods: {
        on_hidden() {
            if (this.addNewUser) {
                this.resetForm()
            }
        },
        onSubmit() {

            this.userData.category = this.categorySelected

            store.dispatch('app-products/' + (this.addNewUser ? 'addProduct' : 'editProduct'), this.userData)
                .then(() => {
                    this.$emit('refetch-data')
                    this.$emit('update:is-add-new-user-sidebar-active', false)
                })

        },
        onImageSelected(e) {
            if (e.target.files.length > 0) {
                this.loading = true
                const image = e.target.files[0]
                this.multimediaObject = {
                    name: image.name,
                    image
                }

                store.dispatch('app-products/uploadImage', this.multimediaObject).then(url => {
                    this.userData.images = [url]
                    this.loading = false
                })
            }
        },
        onDeleteImage() {
            this.$swal({
                title: '¿Seguro que desea eliminar la imagen?',
                text: "Se borrará el archivo permanentemente",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    store.dispatch('app-products/deleteImage', this.userData.images[0]).then(() => {
                        this.userData.images = []
                        this.loading = false
                    })
                }
            })
        }
    },
    data() {
        return {
            required,
            alphaNum,
            email,
        }
    },
    setup() {
        const toast = useToast()

        const blankUserData = {
            category: null,
            description: '',
            name: '',
            price: 0,
            status: true,
            images: []
        }

        const loading = ref(true)

        const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
        const resetuserData = () => {
            userData.value = JSON.parse(JSON.stringify(blankUserData))
        }

        const {
            refFormObserver,
            getValidationState,
            resetForm,
        } = formValidation(resetuserData)

        const categories = ref([])
        const categorySelected = ref('')

        store
            .dispatch('app-products/fetchCategories')
            .then(response => {
                categories.value = response
                loading.value = false
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error al obtener listado de categorías',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })

        const multimediaObject = ref(null)

        return {
            userData,
            refFormObserver,
            getValidationState,
            resetForm,
            categories,
            categorySelected,
            multimediaObject,
            loading
        }
    },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
