import firebase from "firebase/compat/app"
//import { deleteUser  } from "firebase/auth"

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchProducts() {
            return new Promise(resolve => {
                firebase
                    .firestore()
                    .collection('products')
                    .get()
                    .then(snapshot => {
                        let users = []
                        snapshot.docs.forEach(u => {
                            const user = u.data()
                            user.id = u.id

                            users.push(user)
                        })


                        resolve(users)
                    })
            })
        },
        fetchCategories() {
            return new Promise(resolve => {
                firebase
                    .firestore()
                    .collection('categories')
                    .get()
                    .then(snapshot => {
                        let users = []
                        snapshot.docs.forEach(u => {
                            const user = u.data()
                            user.id = u.id

                            users.push(user)
                        })


                        resolve(users)
                    })
            })
        },
        uploadImage(ctx, data) {
            return new Promise(resolve => {
                const storageRef = firebase.storage().ref('/productos/' + data.name).put(data.image)

                storageRef.then(() => {
                    storageRef.snapshot.ref.getDownloadURL().then(url => {
                        resolve(url)
                    })
                })
            })
        },
        deleteImage(ctx, url) {
            return new Promise(resolve => {
                const storageRef = firebase.storage().refFromURL(url).delete()

                storageRef.then(() => {
                    resolve(true)
                })
            })
        },
        addProduct(ctx, data) {
            return new Promise(resolve => {
                data.category = firebase.firestore().doc('categories/' + data.category)

                firebase.firestore().collection('products').add(data).then(() => {
                    resolve(true)
                })
            })
        },
        editProduct(ctx, data) {
            return new Promise(resolve => {
                data.category = firebase.firestore().doc('categories/' + data.category)

                firebase.firestore().collection('products').doc(data.id).set(data).then(() => {
                    resolve(true)
                })
            })
        }
        /* addUser(ctx, userData) {
             return new Promise((resolve, reject) => {
                 firebase.auth().createUserWithEmailAndPassword(userData.username, userData.password).then(() => {
                     firebase.firestore().collection('admin_users').add({username: userData.username}).then(() => {
                         resolve(true)
                     })
                 }).catch(err => {
                     reject(err)
                 })
             })
         },*/
        /*editUser(ctx, userData) {
            return new Promise((resolve, reject) => {
                //firebase.auth().up

            })
        },
        deleteUser(ctx, {id}) {
            return new Promise((resolve, reject) => {

            })
        }*/
    }
}
